import React, { useMemo } from 'react';
import styled, {css} from 'styled-components';
import SbEditable from 'components/SbEditableExtended';

import WhiteBox from 'components/blocks/blockPartials/whiteBox';

import theme, {bp} from 'settings/theme';

import calculateBottomSpacingClass from 'components/blocks/utils/calculateBottomSpacingClass';
import multiOptionsToObject from 'components/blocks/utils/multiOptionsToObject';

const createImageUrl = (origUrl, width) => {
  return origUrl && typeof origUrl === 'string' ? `${origUrl}/m/${width}x0/smart` : '';
}


// Component
const Component = (props) => {

  const displaySettings = useMemo(() => multiOptionsToObject(props.block?.display_settings), [props.block?.display_settings]);
  const spacingBottomClass = useMemo(() => calculateBottomSpacingClass(props.block?.bottom_spacing), [props.block?.bottom_spacing]);

  const imageUrl = props.block?.image?.filename || props.block?.image;

  if(!props.block){
    return(<div>Storyblok block missing</div>);
  }

	return(
    <SbEditable content={props.block}>
      <StyledComponent {...props} className={`blockLargeImage ${spacingBottomClass}`}>
        <WhiteBox 
          removeWhiteBox={displaySettings.removeWhiteBox} 
        >

          <img 
            src={createImageUrl(props.block.image, 640)} 
            srcSet={`
              ${createImageUrl(imageUrl, 375)} 375w,
              ${createImageUrl(imageUrl, 640)} 640w,
              ${createImageUrl(imageUrl, 960)} 960w,
              ${createImageUrl(imageUrl, 1200)} 1200w,
              ${createImageUrl(imageUrl, 1400)} 1400w
            `}
            sizes={`
              100vw
            `}
            alt={props.block.image_alt_text ?? 'Photo'}
          />

          {props.block.caption &&
            <div className="blockLargeImage-caption">
              {props.block.caption}
            </div>
          }

        </WhiteBox>
      </StyledComponent>
    </SbEditable>
	)

}

export default Component


// Styles
const StyledComponent = styled.div`

  &:last-child{
    margin-bottom: 0;
  }

  img{
    width: 100%;
  }

  .blockLargeImage-caption{
    padding: 1rem 10vw;
    font-family: ${theme.typography.fontFamilySecondary};
    font-weight: bold;
    text-align: center;
  }

`